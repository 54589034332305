import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./LoginPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { bech32ToHex, unwrap } from "SnortUtils";
import ZapButton from "Element/ZapButton";
import useImgProxy from "Hooks/useImgProxy";
import Icon from "Icons/Icon";
import useLogin from "Hooks/useLogin";
import { generateNewLogin, LoginStore } from "Login";
import AsyncButton from "Element/AsyncButton";
import useLoginHandler from "Hooks/useLoginHandler";
const KarnageKey = bech32ToHex("npub1r0rs5q2gk0e3dk3nlc7gnu378ec6cnlenqp8a3cjhyzu6f8k5sgs4sq9ac");
// todo: fill more
const Artwork = [
    {
        name: "",
        pubkey: KarnageKey,
        link: "https://void.cat/d/VKhPayp9ekeXYZGzAL9CxP",
    },
    {
        name: "",
        pubkey: KarnageKey,
        link: "https://void.cat/d/3H2h8xxc3aEN6EVeobd8tw",
    },
    {
        name: "",
        pubkey: KarnageKey,
        link: "https://void.cat/d/7i9W9PXn3TV86C4RUefNC9",
    },
    {
        name: "",
        pubkey: KarnageKey,
        link: "https://void.cat/d/KtoX4ei6RYHY7HESg3Ve3k",
    },
];
export async function getNip05PubKey(addr) {
    const [username, domain] = addr.split("@");
    const rsp = await fetch(`https://${domain}/.well-known/nostr.json?name=${encodeURIComponent(username.toLocaleLowerCase())}`);
    if (rsp.ok) {
        const data = await rsp.json();
        const pKey = data.names[username.toLowerCase()];
        if (pKey) {
            return pKey;
        }
    }
    throw new Error("User key not found");
}
export default function LoginPage() {
    const navigate = useNavigate();
    const login = useLogin();
    const [key, setKey] = useState("");
    const [error, setError] = useState("");
    const [art, setArt] = useState();
    const [isMasking, setMasking] = useState(true);
    const { formatMessage } = useIntl();
    const { proxy } = useImgProxy();
    const loginHandler = useLoginHandler();
    const hasNip7 = "nostr" in window;
    const hasSubtleCrypto = window.crypto.subtle !== undefined;
    useEffect(() => {
        if (login.publicKey) {
            navigate("/");
        }
    }, [login, navigate]);
    useEffect(() => {
        const ret = unwrap(Artwork.at(Artwork.length * Math.random()));
        const url = proxy(ret.link);
        setArt({ ...ret, link: url });
    }, []);
    async function doLogin() {
        try {
            await loginHandler.doLogin(key);
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage({ id: "OLEm6z", defaultMessage: "Unknown login error" }));
            }
            console.error(e);
        }
    }
    async function makeRandomKey() {
        await generateNewLogin();
        navigate("/new");
    }
    async function doNip07Login() {
        const relays = "getRelays" in unwrap(window.nostr) ? await unwrap(window.nostr?.getRelays).call(window.nostr) : undefined;
        const pubKey = await unwrap(window.nostr).getPublicKey();
        LoginStore.loginWithPubkey(pubKey, relays);
    }
    function altLogins() {
        if (!hasNip7) {
            return;
        }
        return (_jsx("button", { type: "button", onClick: doNip07Login, children: _jsx(FormattedMessage, { id: 'VtPV/B', defaultMessage: 'Login with Extension (NIP-07)' }) }));
    }
    function installExtension() {
        if (hasSubtleCrypto)
            return;
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex login-or", children: [_jsx(FormattedMessage, { id: '3xCwbZ', defaultMessage: 'OR' }), _jsx("div", { className: "divider w-max" })] }), _jsx("h1", { dir: "auto", children: _jsx(FormattedMessage, { id: 'c+oiJe', defaultMessage: 'Install Extension' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'XzF0aC', defaultMessage: 'Key manager extensions are more secure and allow you to easily login to any Nostr client, here are some well known extensions:' }) }), _jsxs("ul", { children: [_jsx("li", { children: _jsx("a", { href: "https://getalby.com/", target: "_blank", rel: "noreferrer", children: "Alby" }) }), _jsx("li", { children: _jsx("a", { href: "https://chrome.google.com/webstore/detail/nos2x/kpgefcfmnafjgpblomihpgmejjdanjjp", target: "_blank", rel: "noreferrer", children: "nos2x" }) })] }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'lBboHo', defaultMessage: 'If you want to try out some others, check out {link} for more!', values: {
                            link: _jsx("a", { href: "https://github.com/aljazceru/awesome-nostr#browser-extensions", children: "awesome-nostr" }),
                        } }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: '5rOdPG', defaultMessage: 'Once you setup your key manager extension and generated a key, you can follow our new users flow to setup your profile and help you find some interesting people on Nostr to follow.' }) }), hasNip7 ? (_jsx("div", { className: "login-actions", children: _jsx("button", { type: "button", onClick: () => doNip07Login().then(() => navigate("/new/username")), children: _jsx(FormattedMessage, { id: 'nOaArs', defaultMessage: 'Setup Profile' }) }) })) : (_jsx("b", { className: "error", children: _jsx(FormattedMessage, { id: 'VBadwB', defaultMessage: 'Hmm, can\'t find a key manager extension.. try reloading the page.' }) }))] }));
    }
    return (_jsxs("div", { className: "login", children: [_jsx("div", { children: _jsxs("div", { className: "login-container", children: [_jsx("h1", { className: "logo", onClick: () => navigate("/"), children: "Snort" }), _jsx("h1", { dir: "auto", children: _jsx(FormattedMessage, { id: 'odhABf', defaultMessage: 'Login' }) }), _jsx("p", { dir: "auto", children: _jsx(FormattedMessage, { id: 'wLtRCF', defaultMessage: 'Your key' }) }), _jsxs("div", { className: "flex", children: [_jsx("input", { dir: "auto", type: isMasking ? "password" : "text", placeholder: formatMessage({ id: "X7xU8J", defaultMessage: "nsec, npub, nip-05, hex, mnemonic" }), className: "f-grow", onChange: e => setKey(e.target.value) }), _jsx(Icon, { name: isMasking ? "openeye" : "closedeye", size: 30, className: "highlight btn-sm pointer", onClick: () => setMasking(!isMasking) })] }), error.length > 0 ? _jsx("b", { className: "error", children: error }) : null, _jsx("p", { children: _jsx(FormattedMessage, { id: 'o6Uy3d', defaultMessage: 'Only the secret key can be used to publish (sign events), everything else logs you in read-only mode.' }) }), _jsxs("div", { dir: "auto", className: "login-actions", children: [_jsx("button", { type: "button", onClick: doLogin, children: _jsx(FormattedMessage, { id: '9gqH2W', defaultMessage: 'Login' }) }), _jsx(AsyncButton, { onClick: () => makeRandomKey(), children: _jsx(FormattedMessage, { id: '5JcXdV', defaultMessage: 'Create Account' }) }), altLogins()] }), installExtension()] }) }), _jsx("div", { children: _jsx("div", { className: "artwork", style: { ["--img-src"]: `url('${art?.link}')` }, children: _jsxs("div", { className: "attribution", children: [_jsx(FormattedMessage, { id: 'QxCuTo', defaultMessage: 'Art by {name}', values: {
                                    name: _jsx("span", { className: "artist", children: "Karnage" }),
                                } }), _jsx(ZapButton, { pubkey: art?.pubkey ?? "" })] }) }) })] }));
}
