import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";
import messages from "./messages";
export default function LoadMore({ onLoadMore, shouldLoadMore, children, }) {
    const { ref, inView } = useInView();
    const [tick, setTick] = useState(0);
    useEffect(() => {
        if (inView === true && shouldLoadMore === true) {
            onLoadMore();
        }
    }, [inView, shouldLoadMore, tick]);
    useEffect(() => {
        const t = setInterval(() => {
            setTick(x => (x += 1));
        }, 500);
        return () => clearInterval(t);
    }, []);
    return (_jsx("div", { ref: ref, className: "mb10", children: children ?? _jsx(FormattedMessage, { ...messages.Loading }) }));
}
