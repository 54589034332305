import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./LinkPreview.css";
import { useEffect, useState } from "react";
import Spinner from "Icons/Spinner";
import SnortApi from "SnortApi";
import useImgProxy from "Hooks/useImgProxy";
import { MediaElement } from "Element/MediaElement";
async function fetchUrlPreviewInfo(url) {
    const api = new SnortApi();
    try {
        return await api.linkPreview(url);
    }
    catch (e) {
        console.warn(`Failed to load link preview`, url);
    }
}
const LinkPreview = ({ url }) => {
    const [preview, setPreview] = useState();
    const { proxy } = useImgProxy();
    useEffect(() => {
        (async () => {
            const data = await fetchUrlPreviewInfo(url);
            if (data) {
                const type = data.og_tags?.find(a => a[0].toLowerCase() === "og:type");
                const canPreviewType = type?.[1].startsWith("image") || type?.[1].startsWith("video") || false;
                if (canPreviewType || data.image) {
                    setPreview(data);
                    return;
                }
            }
            setPreview(null);
        })();
    }, [url]);
    if (preview === null)
        return (_jsx("a", { href: url, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: url }));
    function previewElement() {
        const type = preview?.og_tags?.find(a => a[0].toLowerCase() === "og:type")?.[1];
        if (type?.startsWith("video")) {
            const urlTags = ["og:video:secure_url", "og:video:url", "og:video"];
            const link = preview?.og_tags?.find(a => urlTags.includes(a[0].toLowerCase()))?.[1];
            const videoType = preview?.og_tags?.find(a => a[0].toLowerCase() === "og:video:type")?.[1] ?? "video/mp4";
            if (link) {
                return _jsx(MediaElement, { url: link, mime: videoType });
            }
        }
        if (type?.startsWith("image")) {
            const urlTags = ["og:image:secure_url", "og:image:url", "og:image"];
            const link = preview?.og_tags?.find(a => urlTags.includes(a[0].toLowerCase()))?.[1];
            const videoType = preview?.og_tags?.find(a => a[0].toLowerCase() === "og:image:type")?.[1] ?? "image/png";
            if (link) {
                return _jsx(MediaElement, { url: link, mime: videoType });
            }
        }
        if (preview?.image) {
            const backgroundImage = preview?.image ? `url(${proxy(preview?.image)})` : "";
            const style = { "--img-url": backgroundImage };
            return _jsx("div", { className: "link-preview-image", style: style });
        }
        return null;
    }
    return (_jsxs("div", { className: "link-preview-container", children: [preview && (_jsxs("a", { href: url, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: [previewElement(), _jsxs("p", { className: "link-preview-title", children: [preview?.title, preview?.description && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("small", { children: preview.description.slice(0, 160) })] }))] })] })), !preview && _jsx(Spinner, { className: "f-center" })] }));
};
export default LinkPreview;
