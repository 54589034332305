import { jsx as _jsx } from "react/jsx-runtime";
import "./Avatar.css";
import Nostrich from "nostrich.webp";
import { useEffect, useState } from "react";
import useImgProxy from "Hooks/useImgProxy";
const Avatar = ({ user, size, onClick }) => {
    const [url, setUrl] = useState(Nostrich);
    const { proxy } = useImgProxy();
    useEffect(() => {
        if (user?.picture) {
            const url = proxy(user.picture, size ?? 120);
            setUrl(url);
        }
        else {
            setUrl(Nostrich);
        }
    }, [user]);
    const backgroundImage = `url(${url})`;
    const style = { "--img-url": backgroundImage };
    const domain = user?.nip05 && user.nip05.split("@")[1];
    return _jsx("div", { onClick: onClick, style: style, className: "avatar", "data-domain": domain?.toLowerCase() });
};
export default Avatar;
