import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./SendSats.css";
import { useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { EventPublisher } from "@snort/system";
import { LNURL, LNURLError, LNURLErrorCode } from "@snort/shared";
import { formatShort } from "Number";
import Icon from "Icons/Icon";
import useEventPublisher from "Feed/EventPublisher";
import ProfileImage from "Element/ProfileImage";
import Modal from "Element/Modal";
import QrCode from "Element/QrCode";
import Copy from "Element/Copy";
import { chunks, debounce } from "SnortUtils";
import { useWallet } from "Wallet";
import useLogin from "Hooks/useLogin";
import { generateRandomKey } from "Login";
import { ZapPoolController } from "ZapPoolController";
import messages from "./messages";
var ZapType;
(function (ZapType) {
    ZapType[ZapType["PublicZap"] = 1] = "PublicZap";
    ZapType[ZapType["AnonZap"] = 2] = "AnonZap";
    ZapType[ZapType["PrivateZap"] = 3] = "PrivateZap";
    ZapType[ZapType["NonZap"] = 4] = "NonZap";
})(ZapType || (ZapType = {}));
export default function SendSats(props) {
    const onClose = props.onClose || (() => undefined);
    const { note, author, target } = props;
    const login = useLogin();
    const defaultZapAmount = login.preferences.defaultZapAmount;
    const amounts = [defaultZapAmount, 1000, 5000, 10000, 20000, 50000, 100000, 1000000];
    const emojis = {
        1000: "👍",
        5000: "💜",
        10000: "😍",
        20000: "🤩",
        50000: "🔥",
        100000: "🚀",
        1000000: "🤯",
    };
    const [handler, setHandler] = useState();
    const [invoice, setInvoice] = useState();
    const [amount, setAmount] = useState(defaultZapAmount);
    const [customAmount, setCustomAmount] = useState();
    const [comment, setComment] = useState();
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [zapType, setZapType] = useState(ZapType.PublicZap);
    const [paying, setPaying] = useState(false);
    const { formatMessage } = useIntl();
    const publisher = useEventPublisher();
    const canComment = handler ? (handler.canZap && zapType !== ZapType.NonZap) || handler.maxCommentLength > 0 : false;
    const walletState = useWallet();
    const wallet = walletState.wallet;
    useEffect(() => {
        if (props.show) {
            setError(undefined);
            setAmount(defaultZapAmount);
            setComment(undefined);
            setZapType(ZapType.PublicZap);
            setInvoice(props.invoice);
            setSuccess(undefined);
        }
    }, [props.show]);
    useEffect(() => {
        if (success && !success.url) {
            // Fire onClose when success is set with no URL action
            return debounce(1000, () => {
                onClose();
            });
        }
    }, [success]);
    useEffect(() => {
        if (props.lnurl && props.show) {
            try {
                const h = new LNURL(props.lnurl);
                setHandler(h);
                h.load().catch(e => handleLNURLError(e, formatMessage(messages.InvoiceFail)));
            }
            catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            }
        }
    }, [props.lnurl, props.show]);
    const serviceAmounts = useMemo(() => {
        if (handler) {
            const min = handler.min / 1000;
            const max = handler.max / 1000;
            return amounts.filter(a => a >= min && a <= max);
        }
        return [];
    }, [handler]);
    const amountRows = useMemo(() => chunks(serviceAmounts, 3), [serviceAmounts]);
    const selectAmount = (a) => {
        setError(undefined);
        setAmount(a);
    };
    async function loadInvoice() {
        if (!amount || !handler || !publisher)
            return null;
        let zap;
        if (author && zapType !== ZapType.NonZap) {
            const relays = Object.keys(login.relays.item);
            // use random key for anon zaps
            if (zapType === ZapType.AnonZap) {
                const randomKey = generateRandomKey();
                console.debug("Generated new key for zap: ", randomKey);
                const publisher = new EventPublisher(randomKey.publicKey, randomKey.privateKey);
                zap = await publisher.zap(amount * 1000, author, relays, note, comment, eb => eb.tag(["anon", ""]));
            }
            else {
                zap = await publisher.zap(amount * 1000, author, relays, note, comment);
            }
        }
        try {
            const rsp = await handler.getInvoice(amount, comment, zap);
            if (rsp.pr) {
                setInvoice(rsp.pr);
                await payWithWallet(rsp);
            }
        }
        catch (e) {
            handleLNURLError(e, formatMessage(messages.InvoiceFail));
        }
    }
    function handleLNURLError(e, fallback) {
        if (e instanceof LNURLError) {
            switch (e.code) {
                case LNURLErrorCode.ServiceUnavailable: {
                    setError(formatMessage(messages.LNURLFail));
                    return;
                }
                case LNURLErrorCode.InvalidLNURL: {
                    setError(formatMessage(messages.InvalidLNURL));
                    return;
                }
            }
        }
        setError(fallback);
    }
    function custom() {
        if (!handler)
            return null;
        const min = handler.min / 1000;
        const max = handler.max / 1000;
        return (_jsxs("div", { className: "custom-amount flex", children: [_jsx("input", { type: "number", min: min, max: max, className: "f-grow mr10", placeholder: formatMessage(messages.Custom), value: customAmount, onChange: e => setCustomAmount(parseInt(e.target.value)) }), _jsx("button", { className: "secondary", type: "button", disabled: !customAmount, onClick: () => selectAmount(customAmount ?? 0), children: _jsx(FormattedMessage, { ...messages.Confirm }) })] }));
    }
    async function payWithWallet(invoice) {
        try {
            if (wallet?.isReady()) {
                setPaying(true);
                const res = await wallet.payInvoice(invoice?.pr ?? "");
                if (props.allocatePool) {
                    ZapPoolController.allocate(amount);
                }
                console.log(res);
                setSuccess(invoice?.successAction ?? {});
            }
        }
        catch (e) {
            console.warn(e);
            if (e instanceof Error) {
                setError(e.toString());
            }
        }
        finally {
            setPaying(false);
        }
    }
    function renderAmounts(amount, amounts) {
        return (_jsx("div", { className: "amounts", children: amounts.map(a => (_jsxs("span", { className: `sat-amount ${amount === a ? "active" : ""}`, onClick: () => selectAmount(a), children: [emojis[a] && _jsxs(_Fragment, { children: [emojis[a], "\u00A0"] }), a === 1000 ? "1K" : formatShort(a)] }, a))) }));
    }
    function invoiceForm() {
        if (!handler || invoice)
            return null;
        return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { ...messages.ZapAmount }) }), amountRows.map(amounts => renderAmounts(amount, amounts)), custom(), _jsx("div", { className: "flex", children: canComment && (_jsx("input", { type: "text", placeholder: formatMessage(messages.Comment), className: "f-grow", maxLength: handler.canZap && zapType !== ZapType.NonZap ? 250 : handler.maxCommentLength, onChange: e => setComment(e.target.value) })) }), zapTypeSelector(), (amount ?? 0) > 0 && (_jsx("button", { type: "button", className: "zap-action", onClick: () => loadInvoice(), children: _jsxs("div", { className: "zap-action-container", children: [_jsx(Icon, { name: "zap" }), target ? (_jsx(FormattedMessage, { ...messages.ZapTarget, values: { target, n: formatShort(amount) } })) : (_jsx(FormattedMessage, { ...messages.ZapSats, values: { n: formatShort(amount) } }))] }) }))] }));
    }
    function zapTypeSelector() {
        if (!handler || !handler.canZap)
            return;
        const makeTab = (t, n) => (_jsx("div", { className: `tab${zapType === t ? " active" : ""}`, onClick: () => setZapType(t), children: n }));
        return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: '+aZY2h', defaultMessage: 'Zap Type' }) }), _jsxs("div", { className: "tabs mt10", children: [makeTab(ZapType.PublicZap, _jsx(FormattedMessage, { id: '/PCavi', defaultMessage: 'Public' })), makeTab(ZapType.AnonZap, _jsx(FormattedMessage, { id: 'wWLwvh', defaultMessage: 'Anon' })), makeTab(ZapType.NonZap, _jsx(FormattedMessage, { id: 'AnLrRC', defaultMessage: 'Non-Zap' }))] })] }));
    }
    function payInvoice() {
        if (success || !invoice)
            return null;
        return (_jsx(_Fragment, { children: _jsxs("div", { className: "invoice", children: [props.notice && _jsx("b", { className: "error", children: props.notice }), paying ? (_jsxs("h4", { children: [_jsx(FormattedMessage, { id: 'vU71Ez', defaultMessage: 'Paying with {wallet}', values: {
                                    wallet: walletState.config?.info.alias,
                                } }), "..."] })) : (_jsx(QrCode, { data: invoice, link: `lightning:${invoice}` })), _jsx("div", { className: "actions", children: invoice && (_jsxs(_Fragment, { children: [_jsx("div", { className: "copy-action", children: _jsx(Copy, { text: invoice, maxSize: 26 }) }), _jsx("button", { className: "wallet-action", type: "button", onClick: () => window.open(`lightning:${invoice}`), children: _jsx(FormattedMessage, { ...messages.OpenWallet }) })] })) })] }) }));
    }
    function successAction() {
        if (!success)
            return null;
        return (_jsxs("div", { className: "success-action", children: [_jsxs("p", { className: "paid", children: [_jsx(Icon, { name: "check", className: "success mr10" }), success?.description ?? _jsx(FormattedMessage, { ...messages.Paid })] }), success.url && (_jsx("p", { children: _jsx("a", { href: success.url, rel: "noreferrer", target: "_blank", children: success.url }) }))] }));
    }
    const defaultTitle = handler?.canZap ? formatMessage(messages.SendZap) : formatMessage(messages.SendSats);
    const title = target
        ? formatMessage(messages.ToTarget, {
            action: defaultTitle,
            target,
        })
        : defaultTitle;
    if (!(props.show ?? false))
        return null;
    return (_jsx(Modal, { className: "lnurl-modal", onClose: onClose, children: _jsxs("div", { className: "lnurl-tip", onClick: e => e.stopPropagation(), children: [_jsx("div", { className: "close", onClick: onClose, children: _jsx(Icon, { name: "close" }) }), _jsxs("div", { className: "lnurl-header", children: [author && _jsx(ProfileImage, { pubkey: author, showUsername: false }), _jsx("h2", { children: props.title || title })] }), invoiceForm(), error && _jsx("p", { className: "error", children: error }), payInvoice(), successAction()] }) }));
}
